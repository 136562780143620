import { useFlags } from "launchdarkly-react-client-sdk";
import { useMemo } from "react";
import z from "zod";

const Flags = z.object({
  enableAccountSettings: z.boolean().default(false),
  enableBlankosPolystream: z.boolean().default(false),
  enableCreatorCrew: z.boolean().default(false),
  enableDevtools: z.boolean().default(false),
  enableLogRocket: z.boolean().default(false),
  enableUphold: z.boolean().default(false),
  paymentProvider: z.enum(["GR4VY", "CYBERSOURCE"]).default("CYBERSOURCE"),
});

const useFeatureFlags = () => {
  const baseFlags = useFlags();
  return useMemo(() => Flags.parse(baseFlags), [baseFlags]);
};

export default useFeatureFlags;
