import isString from "lodash/isString";
import { useContext, useEffect } from "react";
import { CookiePreferencesContext } from "src/providers/CookiePreferencesProvider";

// Calling this v2 since the Zustand version used "cookie-preferences"
export const COOKIE_NAME = "cookie-preferences-v2";

interface UseCookiePreferencesType {
  loaded: boolean;
  preferences: CookiesPreferences;
  acceptAll: () => void;
  setPreferences: (preferences: CookiesPreferences) => void;
}

export interface CookiesPreferences {
  acknowledged?: boolean;
  acknowledgedTimestamp?: Date;
  accepted?: boolean;
  acceptedTimestamp?: Date;
  advanced: {
    saleOfPersonalData: boolean;
    socialMedia: boolean;
    targeting: boolean;
    performance: boolean;
  };
}

type PluginRestrictions = {
  plugins?: {
    [name: string]: boolean | undefined;
  };
};

export const useCookiePreferences = (): UseCookiePreferencesType => {
  const { cookiePreferences, setCookiePreferences, loaded, setLoaded } =
    useContext(CookiePreferencesContext);

  // There is an issue where when a query parameter is provided in the URL,
  // the tracking methods are updated and broken for GTM events. To prevent
  // us from pulling in the broken update we memoize the initial method.

  /**
   * If `loaded` changes before the effect has fired, the initial request
   * will be cancelled and prevent another call. This reduces the number
   * of events that get fired when multiple initial rendered components
   * make use of `useCookiePreferences`
   */
  useEffect(() => {
    if (loaded) return;

    /**
     * There is no way to cancel async functions in JS. However, timeouts
     * are able to be cancelled via `clearTimeout`, effectively cancelling
     * the enqueued function call
     */
    const id = setTimeout(setExistingPreferences);
    return () => clearTimeout(id);
  }, [loaded]);

  const setExistingPreferences = () => {
    const cookie = localStorage.getItem(COOKIE_NAME);
    if (!isString(cookie)) return;

    const preferences = JSON.parse(cookie);
    setCookiePreferences(preferences);
    setLoaded(true);
  };

  const setPreferences = (newPreferences: CookiesPreferences) => {
    // set the local storage
    localStorage.setItem(COOKIE_NAME, JSON.stringify(newPreferences));
    setCookiePreferences(newPreferences);
  };

  const acceptAll = () => {
    setPreferences({
      ...cookiePreferences,
      acknowledged: true,
      acknowledgedTimestamp: new Date(),
      accepted: true,
      acceptedTimestamp: new Date(),
      advanced: {
        saleOfPersonalData: true,
        socialMedia: true,
        targeting: true,
        performance: true,
      },
    });
  };

  return {
    loaded,
    preferences: cookiePreferences,
    acceptAll,
    setPreferences,
  };
};
