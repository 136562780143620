import useFeatureFlags from "@/hooks/useFeatureFlags";
import logger from "@/logger";
import * as Sentry from "@sentry/react";
import React, { useContext, useEffect, useState } from "react";
import { LOGROCKET_PROJECT, LOGROCKET_RELEASE } from "src/helpers/config";

const LogRocketContext = React.createContext<boolean | undefined>(undefined);
LogRocketContext.displayName = "LogRocket";

const getSentryScope = () =>
  new Promise<Sentry.Scope>((res, rej) => {
    try {
      Sentry.configureScope(res);
    } catch (e) {
      rej(e);
    }
  });

/** Initialized LogRocket if enabled. */
const initLogRocket = async (): Promise<boolean> => {
  const LogRocket = (await import("logrocket")).default;
  const setupLogRocketReact = (await import("logrocket-react")).default;
  LogRocket.init(LOGROCKET_PROJECT, {
    release: LOGROCKET_RELEASE,
    shouldCaptureIP: true,
    dom: {
      inputSanitizer: true,
    },
    console: {
      shouldAggregateConsoleErrors: true,
    },
  });

  const getSessionUrl = () =>
    new Promise<string>((res, rej) => {
      try {
        LogRocket.getSessionURL(res);
      } catch (e) {
        rej(e);
      }
    });

  setupLogRocketReact(LogRocket);

  const sessionUrl = await getSessionUrl();
  const sentryScope = await getSentryScope();
  sentryScope.setExtra("sessionURL", sessionUrl);
  return true;
};

export const LogRocketProvider: React.FC = ({ children }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const enableLogRocket = useFeatureFlags().enableLogRocket;

  // wrap in a useEffect so it's only called on the client
  useEffect(() => {
    if (enableLogRocket) {
      initLogRocket()
        .then(setIsInitialized)
        .catch((err) => {
          logger.error(err, "Failed to initialize LogRocket");
          setIsInitialized(false);
        });
    }
  }, [enableLogRocket]);

  return (
    <LogRocketContext.Provider value={isInitialized}>
      {children}
    </LogRocketContext.Provider>
  );
};

/** Determine whether LogRocket is available to a React component. */
export const useIsLogRocketAvailable = () => {
  const context = useContext(LogRocketContext);
  if (context === undefined) {
    logger.error(
      "useIsLogRocketAvailable was called without being in LogRocketProvider scope",
    );
    return false;
  }

  return context;
};
