import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useWindow } from "./useWindow";

/** Retrieve UrlSearchParams from Window on the client. */
export const useUrlParams = () => {
  const [urlParams, setUrlParams] = useState<URLSearchParams>(
    new URLSearchParams(),
  );
  const window = useWindow();

  useEffect(() => {
    if (isEmpty(window)) return;
    const clientUrlParams = new URLSearchParams(window?.location.search);
    if (!clientUrlParams) return;
    setUrlParams(clientUrlParams);
  }, [window]);

  return urlParams;
};
