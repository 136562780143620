import _ from "lodash";
import type { AxiosError, AxiosResponse } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAuth } from "src/hooks/useAuth";
import type {
  AccountDetails,
  UpdateAccountDetailsRequest,
} from "src/services/bbp-backend/account/details";
import type { UpdateAccountEmailRequest } from "../services/bbp-backend/account/email";
import {
  getAccountDetails,
  saveAccountDetails,
} from "src/services/bbp-backend/account/details";
import { saveNewAccountEmail } from "src/services/bbp-backend/account/email";
import type { BlankosApiError } from "src/services/types";

export const useAccountDetails = () => {
  const { isLoggedIn } = useAuth();
  return useQuery<AccountDetails, AxiosError<BlankosApiError> | Error>(
    "accountDetails",
    getAccountDetails,
    {
      staleTime: Infinity,
      enabled: isLoggedIn,
    },
  );
};

export const useSetAccountDetails = () => {
  const queryClient = useQueryClient();
  return useMutation<
    void,
    AxiosError<BlankosApiError>,
    UpdateAccountDetailsRequest,
    { previousAccountDetails?: UpdateAccountDetailsRequest }
  >("useSetAccountDetails", saveAccountDetails, {
    onMutate: async (newAccountDetails) => {
      await queryClient.cancelQueries("accountDetails");

      const previousAccountDetails = _.cloneDeep(
        queryClient.getQueryData<AccountDetails>("accountDetails"),
      );

      queryClient.setQueryData<AccountDetails>("accountDetails", (old) => ({
        ...old,
        ...newAccountDetails,
      }));
      return { previousAccountDetails };
    },
    onError: (err, newAccountDetails, context) => {
      queryClient.setQueryData(
        "accountDetails",
        context?.previousAccountDetails,
      );
    },
    //onSettled: () => queryClient.invalidateQueries("accountDetails"), //commented out for now because back end isn't updated by the time it's refetched
  });
};

export const useSetNewAccountEmail = () => {
  const queryClient = useQueryClient();

  return useMutation<
    AxiosResponse,
    AxiosError<BlankosApiError>,
    UpdateAccountEmailRequest,
    { previousAccountEmail?: UpdateAccountEmailRequest }
  >("useSetNewAccountEmail", saveNewAccountEmail, {
    onMutate: async (newAccountEmail) => {
      await queryClient.cancelQueries("accountEmail");

      const previousAccountEmail = _.cloneDeep(
        queryClient.getQueryData<UpdateAccountEmailRequest>("accountEmail"),
      );

      queryClient.setQueryData<UpdateAccountEmailRequest>(
        "accountEmail",
        (old) => ({
          ...old,
          ...newAccountEmail,
        }),
      );
      return { previousAccountEmail };
    },
    onError: (err, newAccountEmail, context) => {
      queryClient.setQueryData("accountEmail", context?.previousAccountEmail);
    },
    onSettled: () => queryClient.invalidateQueries("accountDetails"),
  });
};
