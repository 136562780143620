import type { PageData } from "analytics";
import { useCallback, useEffect, useMemo } from "react";
import { useCookiePreferences } from "src/components/cookies/useCookiePreferences";
import { useAnalytics as useAnalyticsHook } from "use-analytics";

/** Wrap use-analytics to take into account cookie preferences when sending events to snowplow.
 *  Right now, we are only using a subset of available methods, but we can add others as we need them
 *  https://github.com/DavidWells/analytics
 * */
export const useAnalytics = () => {
  const { track: initialTrack, page: initialPage } = useAnalyticsHook();
  const { loaded } = useCookiePreferences();

  // There is an issue where when a query parameter is provided in the URL,
  // the tracking methods are updated and broken for GTM events. To prevent
  // us from pulling in the broken update we memoize the initial method.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const track = useMemo(() => initialTrack, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const page = useMemo(() => initialPage, []);

  const localTrack = useCallback(
    (eventName: string, payload?: unknown) => {
      track(eventName, payload);
    },
    [track],
  );

  const localPage = (
    data?: PageData<string>,
    callback?: (...params: unknown[]) => unknown,
  ) => {
    page(data, callback);
  };

  const useTrackPageView = (
    data?: PageData<string>,
    callback?: (...params: unknown[]) => unknown,
  ) => {
    useEffect(() => {
      if (loaded) localPage(data, callback);
    }, [callback, data]);
  };

  return {
    track: localTrack,
    page: localPage,
    useTrackPageView,
    cookiesLoaded: loaded,
  };
};
