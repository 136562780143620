import isNil from "lodash/isNil";
import { useQuery } from "react-query";
import { useState } from "react";
import getSession from "../services/getSession";
import type { Session } from "next-auth";

interface HookData {
  data: Session | null;
  status: "authenticated" | "loading" | "unauthenticated";
}

export const useSession = (): HookData => {
  const [data, setData] = useState<Session | null>(null);

  const query = useQuery<Session | null>(["session"], getSession, {
    onSettled(resData) {
      if (resData) {
        setData(resData);
      }
    },
  });

  let status: HookData["status"] = "unauthenticated";
  if (query.status === "loading") {
    status = "loading";
  }
  if (query.status === "idle" || query.status === "success") {
    if (!isNil(query.data)) {
      status = "authenticated";
    }
  }

  return { data, status };
};

export default useSession;
