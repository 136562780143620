import googleTagManager from "@analytics/google-tag-manager";
import { Analytics } from "analytics";
import logger from "@/logger";
import _ from "lodash";

const IS_DEV_ENV = process.env.NODE_ENV === "development";

type PluginCallbackArgs = { payload: unknown };

/*
  custom plugin to log event when developing locally, to view, set NEXT_PUBLIC_LOG_LEVEL=trace
 */
const loggerPlugin = {
  name: "logger",
  page: ({ payload }: PluginCallbackArgs) =>
    logger.trace({ payload }, "analytics/page"),
  track: ({ payload }: PluginCallbackArgs) => {
    const event = _.get(payload, "event");
    logger.trace({ payload }, "analytics/track: %s", event ?? "unknown");
  },
  identify: ({ payload }: PluginCallbackArgs) =>
    logger.trace({ payload }, "analytics/identify"),
};

const devOnlyPlugins = [loggerPlugin];

const getPlugins = ({ gtmContainerId }: AnalyticsVariables) => {
  const plugins = [];
  if (gtmContainerId) {
    plugins.push(
      googleTagManager({
        containerId: gtmContainerId,
      }),
    );
  }

  return plugins;
};

export type AnalyticsVariables = {
  gtmContainerId?: string;
};

/* Initialize analytics & load plugins */
export const analytics = (variables: AnalyticsVariables) => {
  return Analytics({
    plugins: IS_DEV_ENV ? devOnlyPlugins : getPlugins(variables),
    // leaving here for testing purposes
    // plugins: getPlugins(variables),
  });
};

/** Send something directly to the GTM data layer. Necessary for cookie preferences. */
export const gtmTrackRaw = (config: AnalyticsVariables, data: unknown) => {
  if (IS_DEV_ENV) return;
  analytics(config).on("ready", () => window.dataLayer?.push(data));
};

export default analytics;
