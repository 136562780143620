export const analyticsEvents = {
  PAGE_VIEW: "pageView",
  CREATOR_CREW_OPEN_MODAL: "openCreatorApplyModal",
  CREATOR_CREW_APPLICATION_COMPLETE: "creatorApplied",
  DOWNLOAD_BUTTON_CLICKED: "downloadButtonClicked",
  DOWNLOAD_CTA_CLICKED: "downloadCtaClicked",
  DOWNLOAD_STARTED: "downloadStarted",
  FORM_FIELD_ERROR: "formFieldError",
  FORM_FIELD_ERROR_CLEARED: "formFieldErrorCleared",
  FORM_FIELD_TOUCHED: "formFieldTouched",
  LOGIN_CLICKED: "loginClicked",
  MARKETPLACE_EMAIL_SIGNUP: "marketplaceEmailSignup",
  PAYMENT_CANCELLED: "paymentCancelled",
  PAYMENT_METHOD_REMOVED: "paymentMethodRemoved",
  PAYMENT_TOKENIZED: "paymentTokenized",
  PURCHASE: "purchase",
  PURCHASE_DISCLAIMER_CHECKED: "purchaseDisclaimerChecked",
  REGISTER_MODAL_ABANDON: "registerModalAbandon",
  REGISTER_MODAL_OPEN: "registerModalOpen",
  REGISTER_MODAL_SUCCESS: "registerModalSuccess",
  SIGNUP_CLICKED: "signupClicked",
  SIGNUP_CREATED: "signupCreated",
  SIGNUP_ERROR: "signupError",
  SIGNUP_STARTED: "signupStarted",
  SHOP_SHARE_ITEM: "shopShareItem",
  SUPPORT_CLICKED: "supportClicked",
  UPDATE_CART: "updateCart",
  VIEW_CHECKOUT_PAYMENT: "viewCheckoutPayment",
  VIEW_DISCLAIMER: "viewDisclaimer",
  FAQ_CLICKED: "faqClicked",
  SET_COOKIES_PREFERENCES: "setCookiesPreferences",
  SET_MYTHICAL_ID: "setMythicalId",
  REDIRECTED_TO_MARKETPLACE: "redirected_to_marketplace",
  LOAD_COOKIES_PREFERENCES: "loadCookiesPreferences",
  SELF_DESCRIBING_EVENT: "selfDescribingEvent",
  COMPLETE_PURCHASE_CLICKED: "complete_purchase_clicked",
  PAYMENT_METHOD_SELECTED: "payment_method_selected",
  PAYMENT_METHOD_ADDED: "payment_method_added",
  PURCHASE_ERROR: "purchase_error",
  CHECKOUT_CLICKED: "checkout_clicked",
  BILLING_ADDRESS_ADDED: "billing_address_added",
};

export default analyticsEvents;
