import { useUserAuthAnalytics } from "@/hooks/analyticsDeprecated/useUserAuthAnalytics";
import { useAuth } from "@/hooks/useAuth";
import { useRedirect } from "@/hooks/useRedirect";
import { useUrlParams } from "@/hooks/useUrlParams";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { isIE } from "react-device-detect";
import { Hydrate, QueryClient, QueryClientProvider } from "react-query";
import type { AppPageProps } from "src/pages/_app";
import { LogRocketProvider } from "src/providers/LogRocketProvider";
import { CookiePreferencesProvider } from "../providers/CookiePreferencesProvider";
import { MythicalThemeProvider } from "../providers/mythicalThemeProvider/MythicalThemeProvider";
import AuthCheck from "../services/AuthCheck";

const AnalyticsAuthProvider = () => {
  const dispatch = useUserAuthAnalytics();

  const { mythicalId, mythicalUsername } = useAuth();
  useEffect(() => {
    if (mythicalId === undefined) return;
    dispatch(mythicalId, mythicalUsername);
  }, [dispatch, mythicalId, mythicalUsername]);

  return <></>;
};

/**
 * Providers wrapper for all top-level app providers.
 *
 * dehydratedState can be provided from SSG/SSR as described here: https://react-query.tanstack.com/guides/ssr
 *
 * @returns Providers needed for the application to function.
 */
const AppProviders: React.FC<AppPageProps> = ({
  children,
  dehydratedState,
}) => {
  const router = useRouter();
  const params = useUrlParams();
  const gsidParam = params.get("gsid");
  const sdidParam = params.get("sdid");

  if (gsidParam) sessionStorage.setItem("gsidParam", gsidParam);
  if (sdidParam) sessionStorage.setItem("sdidParam", sdidParam);

  const [queryClient] = useState(() => new QueryClient());

  //Looks for a parameter in url named state
  //if state exists, decode it
  //if state object contains property redirectUrl, go to it
  const FusionAuthRedirect: React.FC = () => {
    useRedirect();
    return <></>;
  };

  if (router.isReady && isIE) {
    router.push("/ie11");
    return <></>;
  }

  return (
    <LogRocketProvider>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={dehydratedState}>
          <CookiePreferencesProvider>
            <AnalyticsAuthProvider />
            <FusionAuthRedirect />
            <AuthCheck />
            <MythicalThemeProvider>{children}</MythicalThemeProvider>
          </CookiePreferencesProvider>
        </Hydrate>
      </QueryClientProvider>
    </LogRocketProvider>
  );
};

export default AppProviders;
