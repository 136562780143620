import _ from "lodash";
import fp from "lodash/fp";
import config from "src/config";
import { z } from "zod";

/**
 * Key is required separately for coherent error messages, as you cannot access next env variables by key.
 * @throws {Error} when the value is undefined so we can fail fast
 */
const throwIfUndefined = (key: string) =>
  fp.cond<unknown, string>([
    [
      fp.isUndefined,
      (_v) => {
        throw new Error(`Could not read environment variable: ${key}`);
      },
    ],
    [fp.stubTrue, fp.identity],
  ]);

/**
 * Key is required separately for coherent error messages, as you cannot access next env variables by key.
 * The curried function will determine if a value provided to it can be parsed to a number; if not, it'll throw an error.
 */
const parseNumber = (key: string) =>
  _.flow(
    fp.toNumber,
    fp.cond<number, number>([
      [
        fp.isNaN,
        () => {
          throw new Error(
            `Could not parse environment variable to number: ${key}`,
          );
        },
      ],
      [fp.stubTrue, fp.identity],
    ]),
  );

/** Get environment variable if defined, otherwise throw error. */
export const envVariableToString = (key: string, value: string | undefined) =>
  throwIfUndefined(key)(value);

/** Get environment variable and parse to number if possible, otherwise throw error. */
export const envVariableToNumber = (key: string, value: string | undefined) =>
  _.flow(throwIfUndefined(key), parseNumber(key))(value);

export const envVariableToBoolean = (key: string, value: string | undefined) =>
  _.flow(throwIfUndefined(key), (str) => /^true$/i.test(str))(value);

export const LOGROCKET_PROJECT = envVariableToString(
  "NEXT_PUBLIC_LOGROCKET_PROJECT",
  process.env.NEXT_PUBLIC_LOGROCKET_PROJECT,
);

export const LOGROCKET_RELEASE = config.logRocketRelease;
//#endregion

//#region Analytics

export const GTM_ID = envVariableToString(
  "NEXT_PUBLIC_GTM_ID",
  process.env.NEXT_PUBLIC_GTM_ID,
);
//#endregion

//#region Logging
export const LOG_LEVEL = z
  .string()
  .transform(fp.toLower)
  .and(
    z.enum(["fatal", "error", "warn", "info", "debug", "trace"], {
      required_error:
        "environment variable NEXT_PUBLIC_LOG_LEVEL cannot be undefined",
    }),
  )
  .parse(process.env.NEXT_PUBLIC_LOG_LEVEL);
//#endregion

//#region LaunchDarkly
export const LAUNCHDARKLY_CLIENT_ID = envVariableToString(
  "NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_ID",
  process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_ID,
);
//#endregion
