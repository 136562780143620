import globalRoutes from "../../routes";

const FooterRoutes = {
  DownloadLauncher: globalRoutes.Download,

  Faqs: globalRoutes.Faqs,

  TermsOfUse: globalRoutes.TermsOfUse,
};

export default FooterRoutes;
