import React from "react";
import { BlankosFooter } from "./blankosFooter/BlankosFooter";
import Image from "../components/Image";
import { useAnalytics } from "@/hooks/analytics";
import analyticsEvents from "@/hooks/analytics/analyticsEvents";

const Footer = () => {
  const { track } = useAnalytics();

  const dispatchDownloadClicked = () => {
    track(analyticsEvents.DOWNLOAD_CTA_CLICKED);
  };

  return (
    <footer>
      <BlankosFooter
        rootUrl={new URL("https://www.blankos.com")}
        mythicalIcon={
          <Image
            src="/images/icons/mythical_m_logo.png"
            alt="Mythical icon"
            width={100}
            height={100}
          />
        }
        thirdKindIcon={
          <Image
            src="/images/icons/thirdkind_logo.png"
            alt="Third Kind Games icon"
            width={100}
            height={100}
          />
        }
        discordIcon={
          <Image
            src="/images/icons/discord_logo.png"
            alt="Discord icon"
            width={25}
            height={25}
          />
        }
        handleLauncherClick={dispatchDownloadClicked}
      ></BlankosFooter>
    </footer>
  );
};

export default Footer;
