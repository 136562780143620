import { analyticsConfig } from "@/hooks/analytics";
import useFeatureFlags from "@/hooks/useFeatureFlags";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import type { NextPageWithLayout } from "next";
import type { AppProps } from "next/app";
import React from "react";
import type { DehydratedState } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ThirdPartyScripts } from "src/components/ThirdPartyScripts";
import { LAUNCHDARKLY_CLIENT_ID } from "src/helpers/config";
import AppProviders from "../components/AppProviders";
import BrowserLayout from "../layouts/BrowserLayout";
import { AnalyticsProvider } from "../providers/AnalyticsProvider";
import "../styles/global.css";

type AppPropsWithLayout<P = Record<never, never>> = AppProps<P> & {
  Component: NextPageWithLayout<P>;
  err?: unknown;
};

/** See https://stackoverflow.com/questions/64722812/what-typescript-type-should-nextjs-app-tsx-component-and-pageprops-be/64722865 for why page props is 'any'*/
export type AppPageProps = Record<string, unknown> & {
  dehydratedState?: DehydratedState;
};

const App: React.FC<AppPropsWithLayout<AppPageProps>> = ({
  Component,
  pageProps,
  // Workaround for https://github.com/vercel/next.js/issues/8592
  err,
}) => {
  const Layout =
    typeof Component.getLayout === "function"
      ? Component.getLayout(pageProps)
      : BrowserLayout;
  const enableDevtools = useFeatureFlags().enableDevtools;

  return (
    <>
      <ThirdPartyScripts />
      <AnalyticsProvider config={analyticsConfig}>
        {/*App Providers has to be wrapped with analytics provider since app providers utilizes analytics function*/}
        <AppProviders {...pageProps}>
          <Layout>
            <Component {...pageProps} err={err} />
          </Layout>
          {enableDevtools && <ReactQueryDevtools initialIsOpen={false} />}
        </AppProviders>
      </AnalyticsProvider>
    </>
  );
};

export default withLDProvider<AppPropsWithLayout<AppPageProps>>({
  clientSideID: LAUNCHDARKLY_CLIENT_ID,
})(App);
