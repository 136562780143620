import logger from "@/logger";
import { setUser as setSentryUser } from "@sentry/react";
import _ from "lodash";
import fp from "lodash/fp";
import { useAnalytics } from "@/hooks/analytics";
import { analyticsEvents } from "@/hooks/analytics/analyticsEvents";
import { useCallback } from "react";

export interface UserAnalyticsData {
  mythicalId: string;
}

/**
 * Provides the authentication information to Sentry and LogRocket.
 *
 * Prefer {@link useUserAuthAnalytics} hook; only use this if something is running outside the component cycle.
 */
export const setUser = (id: string, email?: string | null) => {
  const emailData: Record<string, never> | { email: string } = _.isString(email)
    ? { email }
    : {};
  setSentryUser({ id, ...emailData });
  import("logrocket")
    .then(fp.get("default"))
    .then((LogRocket) => {
      if (LogRocket === undefined) return;
      LogRocket.identify(id, emailData);
    })
    .catch((e) => {
      logger.error(e, "LogRocket encountered an error while setting user.");
    });
};

export const useUserAuthAnalytics = () => {
  const { track } = useAnalytics();
  return useCallback(
    (userId: string, email?: string | null) => {
      track(analyticsEvents.SET_MYTHICAL_ID, { mythicalId: userId });
      setUser(userId, email);
    },
    [track],
  );
};
