import _ from "lodash";
import fp from "lodash/fp";
import config from "src/config";
import api from "src/services/bbp-backend/client";
import type * as Contracts from "./contracts";
import type * as Models from "./models";

const baseUrl = `${config.apiUrl}/account/details`;

const parseAccountDetails = (
  contract: Contracts.AccountDetailsContract,
): Models.AccountDetails => {
  return _.omitBy(
    {
      ...contract,
      date:
        typeof contract?.displayNameChangeAllowedAt === "string"
          ? new Date(contract.displayNameChangeAllowedAt)
          : null,
    },
    fp.isNil,
  );
};

export const getAccountDetails = async (): Promise<Models.AccountDetails> => {
  const res = await api.get<Contracts.AccountDetailsContract>(baseUrl);
  if (res.status !== 200) {
    throw new Error(`Invalid status on getting account details: ${res.status}`);
  }

  return parseAccountDetails(res.data);
};

export const saveAccountDetails = async (
  request: Models.UpdateAccountDetailsRequest,
): Promise<void> => {
  api.put<Contracts.UpdateAccountDetailsRequestContract>(baseUrl, request);
};

export * from "./models";
