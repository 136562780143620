import { analyticsEvents } from "@/hooks/analytics/analyticsEvents";
import { useAnalytics } from "@/hooks/analytics/useAnalytics";
import { useAccountDetails } from "@/hooks/useAccountDetails";
import { useAuth } from "@/hooks/useAuth";
import useFeatureFlags from "@/hooks/useFeatureFlags";
import {
  Button,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import NextLink from "next/link";
import { useRouter } from "next/router";
import React, { useState } from "react";
import Routes from "../routes";
import DownloadButton from "./download/DownloadButton";

interface Props {
  mobile?: boolean;
}

const useStyles = makeStyles({
  MuiTypographyBody1: {
    fontWeight: "bold",
  },
});

const UserDropdown = ({ mobile = false }: Props) => {
  const auth = useAuth();
  const { track } = useAnalytics();

  const { data: accountDetails } = useAccountDetails();
  if (!auth.initialized) return <></>;

  const obscureEmail = (email?: string | null) => {
    if (!email) return;

    const [name, domain] = email.split("@");

    const [provider, site] = domain.split(".");
    const maskedProvider = provider[0] + provider[1];

    return `${name[0]}•••@${maskedProvider}•••.${site}`;
  };

  const userEmail = obscureEmail(
    accountDetails?.emailAddress ?? auth.mythicalUsername,
  );

  const onSignUp = () => {
    track(analyticsEvents.SIGNUP_CLICKED);

    if (auth.signUp) return auth.signUp();
  };

  return mobile ? (
    auth.isLoggedIn ? (
      <LoggedInMobile username={userEmail} onLogout={auth.logout} />
    ) : (
      <NotLoggedInMobile onLogin={auth.login} onSignUp={onSignUp} />
    )
  ) : auth.isLoggedIn ? (
    <LoggedIn username={userEmail} onLogout={auth.logout} />
  ) : (
    <NotLoggedIn onLogin={auth.login} onSignUp={onSignUp} />
  );
};

const NotLoggedInMobile: React.FC<any> = ({ onLogin, onSignUp }) => {
  return (
    <Grid container alignItems="center">
      <Grid container item justifyContent="center" xs={8}>
        <Button
          onClick={onSignUp}
          variant="contained"
          color="default"
          style={{ fontSize: "18px", margin: "11px 0" }}
        >
          sign up
        </Button>
      </Grid>
      <Divider orientation="vertical" flexItem />
      <Grid item style={{ margin: "0 auto" }}>
        <Button onClick={onLogin} variant="text" style={{ fontSize: "18px" }}>
          log in
        </Button>
      </Grid>
    </Grid>
  );
};

const LoggedInMobile: React.FC<any> = ({ username, onLogout }) => {
  const enableAccountSettings = useFeatureFlags().enableAccountSettings;
  const [openAccountSettings, setOpenAccountSettings] = useState(false);
  const classes = useStyles();

  return (
    <div>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        style={{ height: "74px" }}
        wrap="nowrap"
      >
        <Grid item style={{ paddingLeft: "18px" }} zeroMinWidth>
          <Typography noWrap variant="body1" style={{ fontWeight: "bold" }}>
            {username}
          </Typography>
        </Grid>
        {enableAccountSettings ? (
          <Grid item>
            <IconButton
              onClick={() => setOpenAccountSettings(!openAccountSettings)}
              style={{ paddingRight: "25px" }}
            >
              {openAccountSettings ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Grid>
        ) : (
          <Grid item style={{ paddingRight: "27px", marginLeft: "20px" }}>
            <Button
              onClick={onLogout}
              variant="text"
              style={{ fontSize: "13.5px", whiteSpace: "nowrap" }}
            >
              Sign out
            </Button>
          </Grid>
        )}
      </Grid>
      <Collapse in={openAccountSettings}>
        <List
          disablePadding
          style={{ height: "calc(100vh - 138px)", paddingLeft: "29px" }}
        >
          <NextLink href={Routes.Account} passHref>
            <Link>
              <ListItem button>
                <ListItemText classes={{ primary: classes.MuiTypographyBody1 }}>
                  My Account
                </ListItemText>
              </ListItem>
            </Link>
          </NextLink>
          <ListItem button onClick={onLogout}>
            <ListItemText
              primary="Sign Out"
              classes={{ primary: classes.MuiTypographyBody1 }}
            />
          </ListItem>
        </List>
      </Collapse>
    </div>
  );
};

const NotLoggedIn: React.FC<any> = ({ onLogin, onSignUp }) => {
  const location = useRouter();
  const isRegistration = location.pathname.includes("register");

  return (
    <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
      {/*Hide download and create account buttons on register page*/}
      {!isRegistration ? (
        <>
          <Grid item>
            <DownloadButton />
          </Grid>
          <Grid item>
            <Button onClick={onSignUp} variant="contained" color="default">
              sign Up
            </Button>
          </Grid>
        </>
      ) : null}
      <Grid item>
        <Button onClick={onLogin} variant="text">
          Log in
        </Button>
      </Grid>
    </Grid>
  );
};

const LoggedIn: React.FC<any> = ({ username, onLogout, onDownload }) => {
  const enableAccountSettings = useFeatureFlags().enableAccountSettings;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid container item justifyContent="flex-end" alignItems="center">
      <Grid item style={{ marginRight: "16px" }}>
        <DownloadButton />
      </Grid>
      <Grid item>
        <Button
          variant="text"
          aria-controls="user-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          {username}
        </Button>
        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          style={{ top: "25px" }}
        >
          <div>
            {enableAccountSettings ? (
              <NextLink href={Routes.Account} passHref>
                <Link>
                  <MenuItem>Account settings</MenuItem>
                </Link>
              </NextLink>
            ) : null}
            <MenuItem onClick={onLogout}>Sign out</MenuItem>
          </div>
        </Menu>
      </Grid>
    </Grid>
  );
};

export default UserDropdown;
