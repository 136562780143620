import { isEmpty } from "lodash";
import { useEffect, useState } from "react";

/** Next SSR prevents retrieving Window globally, so retrieving it in a hook is necessary. */
export const useWindow = () => {
  const [clientWindow, setClientWindow] = useState<typeof window>();

  useEffect(() => {
    if (isEmpty(window)) return;
    setClientWindow(window);
  }, []);

  return clientWindow;
};
