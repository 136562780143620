/* eslint-disable no-console */ //allowable since this file is for handling logging as a whole
import _ from "lodash";
import type { LoggerOptions } from "pino";
import pino from "pino";
import { LOG_LEVEL } from "src/helpers/config";

const isSsr = global.window === undefined;
const serverUrl = process.env.NEXTAUTH_URL ?? "http://localhost:3000";
const apiUri = `${serverUrl}/api/log`;
const IS_DEV_ENV = process.env.NODE_ENV === "development";

const baseOptions: LoggerOptions = {
  name: "blankos.com",
  level: LOG_LEVEL,
};

const clientLogger = (level: typeof LOG_LEVEL) => (o: unknown) => {
  const msg = _.get(o, "msg");
  const consoleLevel = level === "fatal" ? "error" : level;

  if (typeof msg === "string") console[consoleLevel](msg, o);
  else console[consoleLevel](o);
};

/**
 * Configured to send log events of to the next server api route
 */
const clientOptions: LoggerOptions = {
  browser: {
    asObject: true,
    write: {
      debug: clientLogger("debug"),
      error: clientLogger("error"),
      fatal: clientLogger("fatal"),
      info: clientLogger("info"),
      trace: clientLogger("trace"),
      warn: clientLogger("warn"),
    },
    transmit: {
      send: (level, event) => {
        const msg = event.messages;

        const headers = {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
          type: "application/json",
        };
        const blob = new Blob([JSON.stringify({ msg, level })], headers);
        navigator.sendBeacon(apiUri, blob);
      },
    },
  },
};

/**
 * Create the logger instance, reusing existing ones on the client.
 * See {@link https://getpino.io/#/} for usage.
 * @example logger.info({ info_metadata: "Hello world" }, "Info message")
 * @todo Consider a log sink for non-dev environments
 * @returns A logger instance.
 */
const makeLogger = () => {
  // TODO: This should probably be a launchdarkly flag
  if (!IS_DEV_ENV) return pino(baseOptions);
  if (!isSsr) {
    if (window.logger !== undefined) return window.logger;

    const logger = pino({
      ...baseOptions,
      ...clientOptions,
    });

    window.logger = logger;
    logger.info("pino logging started");
    return logger;
  }

  const destination = pino.multistream([
    { level: LOG_LEVEL, stream: pino.destination("last_run.log") },
    { level: LOG_LEVEL, stream: pino.destination(1) },
  ]);
  // server-side options
  return pino(baseOptions, destination);
};

export default makeLogger();
