/* istanbul ignore file */
import { createTheme } from "@material-ui/core";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import createPalette from "@material-ui/core/styles/createPalette";
import createTypography from "@material-ui/core/styles/createTypography";

export const themeConstants = {
  baselineFontSize: 18,
  baselineFontFamily: [
    '"Lato"',
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  headerFontFamily: '"Upgrade Black", sans-serif',
  spacing: 8,
  borderRadius: "14px",
};

const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
});

const palette = createPalette({
  type: "dark",
  primary: {
    main: "#ED1A5C",
  },
  secondary: {
    main: "#1599D1",
    contrastText: "#fff",
  },
  background: {
    default: "#141414",
    paper: "#222326",
  },
  text: {
    primary: "#fff",
    secondary: "rgba(255, 255, 255, 0.7)",
    hint: "#c1c1c1",
  },
  info: {
    main: "#25507E",
  },
  grey: {
    "500": "#1F2124",
    "900": "#141414",
  },
  success: {
    main: "#83be45",
  },
});

export const themeTypography = createTypography(palette, {
  fontFamily: themeConstants.baselineFontFamily,
  fontSize: themeConstants.baselineFontSize,
  htmlFontSize: themeConstants.baselineFontSize,
  allVariants: {
    // lineHeight: "1.5",
  },
  h1: {
    fontFamily: themeConstants.headerFontFamily,
    fontSize: "72px",
    textTransform: "uppercase",
    [breakpoints.down("sm")]: {
      fontSize: "54px",
    },
  },
  h2: {
    fontFamily: themeConstants.headerFontFamily,
    fontSize: "54px",
    textTransform: "uppercase",
    [breakpoints.down("sm")]: {
      fontSize: "47px",
    },
  },
  h3: {
    fontFamily: themeConstants.headerFontFamily,
    fontSize: "47px",
    textTransform: "uppercase",
    [breakpoints.down("sm")]: {
      fontSize: "36px",
    },
  },
  h4: {
    fontFamily: themeConstants.headerFontFamily,
    fontSize: "36px",
    textTransform: "uppercase",
    [breakpoints.down("sm")]: {
      fontSize: "32px",
    },
  },
  h5: {
    fontFamily: themeConstants.headerFontFamily,
    fontSize: "32px",
    textTransform: "uppercase",
    [breakpoints.down("sm")]: {
      fontSize: "27px",
    },
  },
  h6: {
    fontFamily: themeConstants.headerFontFamily,
    fontSize: "18px",
    fontWeight: 900,
    textTransform: "uppercase",
    color: "rgba(255, 255, 255, 0.7)",
  },
  subtitle1: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "rgba(255, 255, 255, 0.7)",
  },
  subtitle2: {
    fontFamily: "monospace",
    fontSize: "18px",
    color: "rgba(255, 255, 255, 0.7)",
  },
  body1: {
    fontFamily: themeConstants.baselineFontFamily,
  },
  body2: {
    lineHeight: "1.8",
  },
  overline: {
    fontFamily: themeConstants.headerFontFamily,
    fontSize: "20px",
    color: palette.text.hint,
  },
});

const theme = createTheme({
  palette,
  typography: themeTypography,
  breakpoints,
  overrides: {
    MuiAccordion: {
      root: {
        border: "1px solid #4B4C52",
        background: "#141414",
        boxShadow: "none",
        "&$expanded": {
          borderColor: palette.secondary.main,
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        fontSize: "22px",
        display: "flex",
        alignContent: "center",
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(0, 0, 0, 0.75)",
      },
    },
    MuiButton: {
      root: {
        display: "inline-flex",
        justifyItems: "center",
        padding: `${themeConstants.spacing}px ${themeConstants.spacing * 2}px`,
        fontWeight: "bold",
        textTransform: "none",
        borderRadius: themeConstants.borderRadius,
      },
      contained: {
        textTransform: "lowercase",
        boxShadow: "none",
        backgroundColor: palette.info.main,
        color: palette.getContrastText(palette.info.main),
        "&:hover": {
          backgroundColor: palette.info.dark,
        },
      },
      containedPrimary: {
        boxShadow: "none",
        backgroundColor: palette.primary.main,
        color: palette.getContrastText(palette.primary.main),
        "&:hover": {
          backgroundColor: palette.primary.dark,
        },
      },
      containedSecondary: {
        boxShadow: "none",
        backgroundColor: palette.secondary.main,
        color: palette.getContrastText(palette.secondary.main),
        "&:hover": {
          backgroundColor: palette.secondary.dark,
        },
      },
      sizeLarge: {
        padding: `${themeConstants.spacing * 2}px ${
          themeConstants.spacing * 4
        }px`,
        fontSize: "20px",
      },
      outlined: {
        borderWidth: "2px",
        background: "#141414",
        textTransform: "lowercase",
        "&:hover": {
          borderWidth: "2px",
        },
      },
      outlinedPrimary: {
        borderWidth: "2px",
        "&:hover": {
          borderWidth: "2px",
        },
      },
      outlinedSecondary: {
        borderWidth: "2px",
        color: "#fff",
        "&:hover": {
          borderWidth: "2px",
        },
      },
      text: {
        padding: "0",
        width: "auto",
        minWidth: "0",
        "&:hover": {
          background: "none",
          textDecoration: "underline",
        },
      },
      textSizeLarge: {
        padding: "0 !important",
      },
    },
    MuiButtonGroup: {
      root: {
        borderRadius: themeConstants.borderRadius,
      },
    },
    MuiCard: {
      root: {
        borderRadius: themeConstants.borderRadius,
        background: "#141414",
      },
    },
    MuiCardHeader: {
      root: {
        background: palette.common.black,
        color: palette.common.white,
        padding: "16px 56px",
      },
      title: {
        fontSize: "20px",
      },
    },
    MuiCardContent: {
      root: {
        background: palette.grey["900"],
        [breakpoints.up("sm")]: {
          padding: "32px",
        },
      },
    },
    MuiDialogContent: {
      root: {
        padding: "0 24px 24px",
      },
    },
    MuiDialogTitle: {
      root: {
        display: "relative",
        "& > .MuiTypography-root": {
          fontFamily: themeConstants.headerFontFamily,
          fontSize: "32px",
          "& > .MuiButtonBase-root": {
            position: "absolute",
            top: "16px",
            right: "16px",
          },
        },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "#4B4C52",
      },
    },
    MuiFilledInput: {
      root: {
        borderTopLeftRadius: themeConstants.borderRadius,
        borderTopRightRadius: themeConstants.borderRadius,
        borderBottomLeftRadius: themeConstants.borderRadius,
        borderBottomRightRadius: themeConstants.borderRadius,
        backgroundColor: palette.background.paper,
        "&:hover, &.Mui-focused": {
          backgroundColor: palette.background.paper,
        },
      },
      input: {
        background: palette.background.paper,
        borderRadius: themeConstants.borderRadius,
      },
    },
    MuiInputBase: {
      root: {
        /* Change Autocomplete styles in browsers */
        "& input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus":
          {
            "-webkit-text-fill-color": "white",
            "-webkit-box-shadow": "0 0 0 100px transparent inset",
            transition: "background-color 5000s ease-in-out 0s",
          },
      },
    },
    MuiLink: {
      root: {
        color: "#fff",
        fontSize: "inherit",
        cursor: "pointer",
      },
    },
    MuiStepper: {
      root: {
        background: "none",
        padding: "0",
      },
    },
    MuiStepLabel: {
      label: {
        fontFamily: themeConstants.headerFontFamily,
      },
    },
    MuiSelect: {
      root: {
        /* Change Autocomplete styles in browsers */
        "&:-webkit-autofill": {
          transitionDelay: "9999s",
          transitionProperty: "background-color, color",
          "-webkit-box-shadow": "0 0 0 100px transparent inset",
        },
      },
      /* Keeps consistent styles with our TextFields */
      select: {
        borderRadius: themeConstants.borderRadius,
        "&:focus": {
          backgroundColor: palette.background.paper,
          borderRadius: themeConstants.borderRadius,
        },
      },
    },
    MuiTab: {
      root: {
        minWidth: "none",
        padding: "0",
        margin: "0 16px",
        fontFamily: themeConstants.headerFontFamily,
        fontSize: "27px",
        "&:first-child": {
          marginLeft: "0",
        },
        [breakpoints.up("sm")]: {
          minWidth: "none",
        },
      },
    },
    MuiTypography: {
      root: {
        fontSize: "20px",
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiButtonGroup: {
      disableRipple: true,
    },
    MuiCard: {
      elevation: 0,
    },
    MuiCircularProgress: {
      color: "secondary",
    },
    MuiFilledInput: {
      disableUnderline: true,
    },
    MuiFormControl: {
      margin: "dense",
    },
    MuiLinearProgress: {
      color: "secondary",
    },
    MuiLink: {
      underline: "always",
    },
  },
});

export default theme;
