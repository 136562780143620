import { signIn as nextSignIn } from "next-auth/react";

/**
 * Yes, this is an extremely simple wrapper. However, this will allow us to
 * switch from Keycloak to FusionAuth by changing a single value in this file
 * rather than searching for all uses of NextAuth's `signIn` method throughout
 * the app.
 *
 * This should be used anywhere you would normally put a call to the built-in
 * `signIn` method from `next-auth/react`.
 */

/*
  GSID is used for tracking, attempt to pull the id from some function in
  the user's cookies.
  Attaches it as a URL parameter for login and adds it to the state parameter for FA
*/
export const signIn = (provider: string, callbackUrl: string) =>
  nextSignIn(provider, {
    callbackUrl: callbackUrl,
  });

export default signIn;
