import config from "src/config";
import api from "src/services/bbp-backend/client";
import type * as Models from "./models";

const baseUrl = `${config.apiUrl}/account/details/email`;

export const saveNewAccountEmail = async (
  request: Models.UpdateAccountEmailRequest,
) => {
  return api.put(baseUrl, request);
};

export * from "./models";
