import React from "react";
import { Hidden } from "@material-ui/core";
import { HeaderNavigation } from "./headerNavigation/HeaderNavigation";
import Image from "./Image";
import UserDropdown from "./UserDropdown";

const Header = () => {
  return (
    <HeaderNavigation
      logoImage={
        <Image
          src="/images/blankos_logo.png"
          alt="Blankos Block Party logo"
          width={151}
          height={63}
        />
      }
      logoImageMobileNav={
        <Image
          src="/images/blankos_logo.png"
          alt="Blankos Block Party logo"
          width={84}
          height={34}
        />
      }
      rootUrl={new URL("https://www.blankos.com")}
    >
      <Hidden lgUp>
        <UserDropdown mobile />
      </Hidden>
      <Hidden mdDown>
        <UserDropdown />
      </Hidden>
    </HeaderNavigation>
  );
};

export default Header;
