import {
  Avatar,
  Button,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { DeleteTwoTone } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import _ from "lodash";
import NextLink from "next/link";
import React from "react";
import NumberFormat from "react-number-format";
import { useCart } from "src/hooks/checkout/shop/useCart";
import { useItemCustomData } from "src/hooks/useItemCustomData";
import { useItemData } from "../../hooks/useItemData";
import Routes from "../../routes";
import QuantitySelector from "../shop/QuantitySelector";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxHeight: "50vh",
    overflow: "auto",
  },
  listItemAvatar: {
    marginRight: theme.spacing(2),
  },
  avatar: {
    height: "80px",
    width: "80px",
  },
  listItemGutters: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  listItemTextPrimary: {
    fontFamily: theme.typography.h1.fontFamily,
    paddingBottom: theme.spacing(2),
    overflow: "hidden",
    wordBreak: "break-word",
  },
  listItemTextSecondary: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.common.white,
  },
}));

const CartSummary = ({ quantitySelector = false }) => {
  const classes = useStyles();

  const items = useCart((state) => state.items);

  return (
    <div className={classes.root}>
      <List>
        {items.map(({ itemId, quantity }) => (
          <CartSummaryItem
            itemId={itemId}
            quantity={quantity}
            key={itemId}
            quantitySelector={quantitySelector}
          />
        ))}
      </List>
    </div>
  );
};

const CartSummaryItem = ({
  itemId,
  quantity,
  quantitySelector,
}: {
  itemId: string;
  quantity: number;
  quantitySelector: boolean;
}) => {
  const classes = useStyles();

  const { item, tabData } = useItemData(itemId);
  const { data: customData } = useItemCustomData(itemId);
  const setItem = useCart((store) => store.setItem);

  const { Start: gradientStart, End: gradientEnd } =
    customData?.BackgroundGradient ?? {};

  const canShowAvatar =
    item !== undefined &&
    tabData !== undefined &&
    tabData.imageUrl !== null &&
    gradientStart !== undefined &&
    gradientEnd !== undefined;

  return (
    <ListItem classes={{ gutters: classes.listItemGutters }}>
      {canShowAvatar ? (
        <NextLink href={Routes.ShopItem(item.itemId)} passHref>
          <Link>
            <ListItemAvatar className={classes.listItemAvatar}>
              <Avatar
                alt={`${item.catalogItem.itemName}`}
                src={tabData.imageUrl as string} // narrowed by canShowAvatar
                variant="rounded"
                className={classes.avatar}
                style={{
                  background: `linear-gradient(to bottom right, ${gradientStart}, ${gradientEnd})`,
                }}
              />
            </ListItemAvatar>
          </Link>
        </NextLink>
      ) : (
        <ListItemAvatar className={classes.listItemAvatar}>
          <Skeleton variant="rect" width="80px" height="80px" />
        </ListItemAvatar>
      )}
      <ListItemText
        primary={
          <Grid container justifyContent="space-between">
            <Grid item>
              {item !== undefined ? (
                <NextLink href={Routes.ShopItem(item.itemId)} passHref>
                  <Link>{item.catalogItem.itemName ?? item.itemId}</Link>
                </NextLink>
              ) : (
                <Skeleton variant="text" width="200px" />
              )}
            </Grid>
            {quantitySelector ? (
              <Grid item>
                <Tooltip placement="top" arrow title="Delete">
                  <Button variant="text" onClick={() => setItem(itemId, 0)}>
                    <DeleteTwoTone />
                  </Button>
                </Tooltip>
              </Grid>
            ) : null}
          </Grid>
        }
        secondary={
          <>
            {quantitySelector && item !== undefined ? (
              <QuantitySelector
                size="small"
                initialQuantity={quantity}
                minQuantity={1}
                onQuantityChange={(q) => setItem(itemId, q)}
                maxQuantity={
                  !_.isNil(item.cartLimit) ? item.cartLimit : undefined
                }
              />
            ) : (
              <>&times; {quantity}</>
            )}
            {typeof item !== "undefined" && item.rmPrice !== null && (
              <NumberFormat
                value={item.rmPrice * quantity}
                prefix="$"
                displayType="text"
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            )}
          </>
        }
        classes={{
          primary: classes.listItemTextPrimary,
          secondary: classes.listItemTextSecondary,
        }}
      />
    </ListItem>
  );
};

export default CartSummary;
