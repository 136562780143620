import getConfig from "next/config";

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();

interface Config {
  serverRuntimeConfig: typeof serverRuntimeConfig;
  gtmId: string;
  deploymentEnv: string;
  siteUrl: string;
  nextauthUrl: string;
  nextauthSecret: string;
  kountUrl: string;
  kountMerchantId: string;
  arkoseLoginPublicKey: string;
  arkoseRegistrationPublicKey: string;
  primeGamingOffersUrl: string;
  keycloakUrl: string;
  keycloakRealm: string;
  lwaClientId: string;
  keycloakClientId: string;
  keycloakClientSecret: string;
  fusionauthId: string;
  fusionauthTenantId: string;
  fusionauthIssuer: string;
  fusionauthSecret: string;
  playerPerm: string;
  apiUrl: string;
  gameDownloadUrlPc: string;
  gameDownloadUrlMac: string;
  storeCatalog: string;
  dropStartTime: string;
  logRocketProject: string;
  logRocketRelease: string;
  googleMapsKey: string;
  shopDropConfigUrl: string;
}

export default { serverRuntimeConfig, ...publicRuntimeConfig } as Config;
