import type { Theme } from "@material-ui/core";
import {
  Button,
  Container,
  IconButton,
  Link,
  makeStyles,
  Switch,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useState } from "react";
import type { CookiesPreferences } from "./useCookiePreferences";

const useStyles = makeStyles((theme: Theme) => ({
  modalContainer: {
    padding: "40px",
    color: "#000",
    backgroundColor: "#fff",
    borderRadius: "5px",
    maxHeight: "90vh",
    margin: "5vh 0",
    overflowY: "scroll",
    boxSizing: "border-box",
    zIndex: 10001,
  },
  cookieH2: {
    textTransform: "none",
    textShadow: "none",
    marginTop: "0",
    marginBottom: theme.spacing(4),
  },
  cookieHeader: {
    fontWeight: "bold",
    textTransform: "none",
    textShadow: "none",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  cookieSubheader: {
    fontWeight: "bold",
    textTransform: "none",
    textShadow: "none",
    marginTop: "16px",
  },
  dateUpdated: {
    fontSize: "18px",
    fontWeight: "bold",
  },
  cookieLink: {
    color: "#5D8B73",
    fontSize: "16px",
  },
  submitButton: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.success.main,
    },
  },
  track: {
    backgroundColor: "rgb(118, 118, 118)",
  },
}));

type CookiePreferencesModalProps = {
  // needs to be passed in to update cookie preferences state properly
  setPreferences: (prefs: CookiesPreferences) => void;
  onClose: () => void;
};

const CookiePreferencesModal: React.FC<CookiePreferencesModalProps> = ({
  setPreferences,
  onClose,
}) => {
  const classes = useStyles();

  const [saleOfPersonalData, setSaleOfPersonalData] = useState(false);
  const [socialCookies, setSocialCookies] = useState(false);
  const [targetingCookies, setTargetingCookies] = useState(false);
  const [performanceCookies, setPerformanceCookies] = useState(false);

  const submitPreferences = () => {
    const now = new Date();

    setPreferences({
      acknowledged: true,
      acknowledgedTimestamp: now,
      accepted: false,
      advanced: {
        performance: performanceCookies,
        saleOfPersonalData: saleOfPersonalData,
        socialMedia: socialCookies,
        targeting: targetingCookies,
      },
    });

    onClose();
  };

  return (
    <Container maxWidth="md">
      <div className={classes.modalContainer}>
        <div style={{ textAlign: "right" }}>
          <IconButton onClick={onClose}>
            <CloseIcon style={{ fontSize: "32px", color: "black" }} />
          </IconButton>
        </div>
        <Typography
          variant="h2"
          className={classes.cookieH2}
          style={{ marginTop: "0" }}
        >
          Mythical Cookies Preferences
        </Typography>
        <p style={{ fontWeight: "bold" }}>Effective Date: June 1, 2020</p>
        <Typography>
          When you visit our website, we store cookies on your browser to
          collect information related to you, your preferences or your device.
          This information is mostly used to make the site work as you expect it
          to and to provide a more personalized web experience. However, you can
          choose not to allow certain types of cookies, which may impact your
          experience of the site and the services we are able to offer. Click on
          the different category headings to find out more and change our
          default settings according to your preference. You cannot opt-out of
          our First Party Strictly Necessary Cookies as they are deployed in
          order to ensure the proper functioning of our website (such as
          prompting the cookie banner and remembering your settings, to log into
          your account, to redirect you when you log out, etc.). For more
          information about the cookies used on our website or to exercise
          additional data privacy rights for downstream vendors see our{" "}
          <Link
            href="https://mythical.games/privacy"
            className={classes.cookieLink}
            rel="noreferrer noopener"
            target="_blank"
            title="Mythical Games Privacy Policy"
          >
            Privacy Policy
          </Link>
          .
        </Typography>

        <Typography
          variant="h3"
          component="h2"
          className={classes.cookieHeader}
        >
          Manage Consent Preferences
        </Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h4"
            component="h3"
            className={classes.cookieHeader}
          >
            First Party Strictly Necessary
          </Typography>
          <span style={{ color: "#5D8B73" }}>Always Active</span>
        </div>
        <Typography>
          These are necessary for the website or application to function and
          cannot be switched off in our systems. They are usually only set in
          response to actions made by you which amount to a request for
          services, such as setting your privacy preferences, logging in or
          filling in forms. You can set your browser or mobile device to block,
          but some parts of the site will not then work. These services do not
          store any personally identifiable information.
        </Typography>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h4"
            component="h3"
            className={classes.cookieHeader}
          >
            Sale of Personal Data
          </Typography>
          <span>
            <Switch
              checked={saleOfPersonalData}
              onChange={(e) => setSaleOfPersonalData(e.target.checked)}
              color="primary"
              classes={{ track: classes.track }}
            />
          </span>
        </div>
        <Typography>
          Under the California Consumer Privacy Act, you have the right to
          opt-out of the sale of your personal information to third parties.
          These cookies collect information for analytics and to personalize
          your experience with targeted ads. You may exercise your right to opt
          out of the sale of personal information by using the toggle switch. If
          you opt out we will not be able to offer you personalized ads and will
          not hand over your personal information to any third parties.
          Additionally, you may contact our legal department for further
          clarification about your rights as a California consumer.
        </Typography>
        <Typography>
          If you have enabled privacy controls on your browser (such as a
          plugin), we have to take that as a valid request to opt-out. Therefore
          we would not be able to track your activity through the web. This may
          affect our ability to personalize ads according to your preferences.
        </Typography>

        <Typography
          variant="h4"
          component="h3"
          className={classes.cookieHeader}
        >
          All Other Cookies
        </Typography>
        <div style={{ marginLeft: "4vw" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h5"
              component="h4"
              className={classes.cookieSubheader}
            >
              Social Media
            </Typography>
            <span>
              <Switch
                checked={socialCookies}
                onChange={(e) => setSocialCookies(e.target.checked)}
                color="primary"
                classes={{ track: classes.track }}
              />
            </span>
          </div>
          <Typography>
            These are set by a range of social media services that we have added
            to the site or application to enable you to share our content with
            your friends and networks. They are capable of tracking your browser
            or device across other sites and building up a profile of your
            interests. This may impact the content and messages you see on other
            websites or applications you visit.{" "}
            <strong>
              If you do not allow, you may not be able to use or see these
              sharing tools.
            </strong>
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h5"
              component="h4"
              className={classes.cookieSubheader}
            >
              Targeting
            </Typography>
            <span>
              <Switch
                checked={targetingCookies}
                onChange={(e) => setTargetingCookies(e.target.checked)}
                color="primary"
                classes={{ track: classes.track }}
              />
            </span>
          </div>
          <Typography>
            These may be set through our site by our advertising partners. They
            may be used by those companies to build a profile of your interests
            and show you relevant ads on other sites. They do not store directly
            personally identifiable information but are based on uniquely
            identifying your browser and internet device.{" "}
            <span style={{ fontWeight: "bold" }}>
              If you do not allow, you may still see advertisements but they
              will be less targeted to your likes.
            </span>
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h5"
              component="h4"
              className={classes.cookieSubheader}
            >
              Performance
            </Typography>
            <span>
              <Switch
                checked={performanceCookies}
                onChange={(e) => setPerformanceCookies(e.target.checked)}
                color="primary"
                classes={{ track: classes.track }}
              />
            </span>
          </div>
          <Typography>
            This allows us to count visits and traffic sources so we can measure
            and improve the performance of our site. They help us to know which
            pages are the most and least popular and see how visitors move
            around the site. All information collected is aggregated and
            therefore anonymous.{" "}
            <strong>
              If you do not allow this service we will not know when you have
              visited our site, and will not be able to monitor its performance.
            </strong>
          </Typography>
        </div>
        <div style={{ textAlign: "center", padding: "32px 0" }}>
          <Button
            onClick={submitPreferences}
            variant="outlined"
            size="large"
            className={classes.submitButton}
          >
            SAVE MY CHOICES
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default CookiePreferencesModal;
