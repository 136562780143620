import _ from "lodash";

const REDIRECT_WHITELIST = [
  "blankos.mythical.market", // Production
  "marketplace.blankos-qa.iviengine.com", // QA
  "marketplace.mythical-qa.iviengine.com", // QA 2 (Stripe)
  "localhost", // Development
];

/** @deprecated Prefer {@link isClientSide} */
const isInBrowser = (): boolean => {
  return typeof window !== "undefined";
};

/** It's safe to use global.window if this returns true.  */
export const isClientSide = () => typeof window !== "undefined";

/** @deprecated Prefer useRouter().query from "next/router" */
const getUrlParams = (): URLSearchParams => {
  if (!isInBrowser()) return new URLSearchParams();

  const queryString = window.location.search;
  return new URLSearchParams(queryString);
};

/** @deprecated Prefer useRouter().query[key] from "next/router" */
const getUrlParam = (key: string): string => {
  const urlParams = getUrlParams();
  return urlParams?.get(key) as string;
};

/** @deprecated Prefer useRouter().query.source from "next/router" */
const getSource = (): string => getUrlParam("source");

export const getRedirectUrl = (): URL | null => {
  const urlParams = getUrlParams();

  try {
    const redirectUri = urlParams?.get("redirect_url");
    if (!_.isString(redirectUri)) return null;
    const redirectUrl = new URL(redirectUri);

    if (_.includes(REDIRECT_WHITELIST, redirectUrl?.hostname)) {
      return redirectUrl;
    }
  } catch {
    // Do nothing
  }

  return null;
};

export const getStoreCatalog = (): string | null => getUrlParam("catalog");

/** ?source=game is considered any embedded view, including in the launcher. */
export const isInGame = (): boolean => {
  if (!isInBrowser()) return false;

  const source = getSource();
  return source === "game";
};

/** @deprecated Prefer {@link isInLauncher} */
export const isFromLauncher = (): boolean => {
  if (!isInBrowser()) return false;

  const source = getSource();
  return source === "launcher";
};

export const isInLauncher = isFromLauncher;

export const isFromMarketplace = (): boolean => {
  if (!isInBrowser()) return false;

  const source = getSource();
  return source === "marketplace";
};

/** True if the web instance is based in the launcher or game  */
export const isEmbeddedView = () =>
  _.some([isInLauncher, isInGame], (isIn) => isIn());

/** @deprecated Prefer WindowHelpers from "src/helpers" */
export const windowHelpers = {
  isInBrowser,
  getUrlParams,
  getUrlParam,
  getSource,
  getRedirectUrl,
  getStoreCatalog,
  isInGame,
  isInLauncher,
  isFromLauncher,
  isFromMarketplace,
};
