import React from "react";
import { AnalyticsProvider as UseAnalyticsProvider } from "use-analytics";
import type { AnalyticsVariables } from "src/hooks/analytics";
import { analytics } from "src/hooks/analytics";

type AnalyticsProviderProps = {
  config: AnalyticsVariables;
  children?: React.ReactNode;
};

export const AnalyticsContext = React.createContext<AnalyticsVariables>({});

export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = (props) => {
  const { children, config = {} } = props;

  return (
    <AnalyticsContext.Provider value={config}>
      <UseAnalyticsProvider instance={analytics({ ...config })}>
        {children}
      </UseAnalyticsProvider>
    </AnalyticsContext.Provider>
  );
};
