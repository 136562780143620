import config from "../config";
import Routes from "../routes";

export const setRedirect = () => {
  localStorage.setItem("REDIRECT_URL", window?.location?.href);

  if (window?.location?.href.includes("register")) {
    localStorage.setItem("REDIRECT_URL", config.siteUrl + Routes.Download);
  }

  if (window?.location?.href.includes("checkout")) {
    localStorage.setItem("REDIRECT_URL", config.siteUrl + Routes.Checkout);
  }
};
