import logger from "@/logger";
import _ from "lodash";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useAuth } from "@/hooks/useAuth";

export function useRedirect() {
  const router = useRouter();
  const { isLoggedIn, initialized } = useAuth();

  useEffect(() => {
    try {
      const storedRedirect = localStorage.getItem("REDIRECT_URL");

      if (typeof storedRedirect === "string" && storedRedirect.length > 0) {
        const currPath = router.asPath;
        const prevUrl = new URL(storedRedirect);
        const prevPath = prevUrl.pathname + prevUrl.search;

        if (initialized) {
          if (currPath !== prevPath) {
            //don't redirect if target is checkout and user is unauthenticated. Prevents redirect loop
            if (!isLoggedIn && storedRedirect.includes("checkout")) {
              localStorage.removeItem("REDIRECT_URL");
              router.replace("/");
              return;
            }
            router.replace(storedRedirect).then(() => {
              localStorage.removeItem("REDIRECT_URL");
            });
          }
        }
      }
    } catch (error: unknown) {
      logger.error({ error }, "useRedirect error");
    }
  }, [router]);
}
