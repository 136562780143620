import axios from "axios";
import _, { merge } from "lodash";
import getSession from "./getSession";

import { isInGame } from "src/helpers/window-helpers";

// passes in the auth token when available
axios.interceptors.request.use(async (config) => {
  if (global.window === undefined) return config;
  const session = await getSession();

  const accessToken = session?.accessToken;
  if (!_.isString(accessToken)) return config;

  return merge(config, {
    headers: {
      Authorization: `Bearer ${session?.accessToken}`,
    },
  });
});

axios.interceptors.request.use(async (config) => {
  if (global.window === undefined) return config;

  if (!isInGame()) {
    return config;
  }

  const accessToken = window.sessionStorage.getItem("accessToken");

  if (accessToken === undefined || accessToken === null) {
    return config;
  }

  return merge(config, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
});

export default axios;
