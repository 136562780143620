import type { Route } from "./Route";
import globalRoutes from "../../routes";

export const Routes: { [key: string]: Route } = {
  Web: {
    name: "Blankos.com",
    url: globalRoutes.Index,
  },
  News: {
    name: "News",
    url: "https://news.mythicalgames.com/category/blankos",
  },
  Community: {
    name: "Community",
    children: {
      Discord: {
        id: "community-social-discord",
        name: "Discord",
        url: "https://discord.gg/playblankos",
        anchorProps: {
          target: "_blank",
          rel: "noreferrer noopener",
        },
      },
      Reddit: {
        id: "community-social-reddit",
        name: "Blankos Subreddit",
        url: "https://www.reddit.com/r/PlayBlankos/",
        anchorProps: {
          target: "_blank",
          rel: "noreferrer noopener",
        },
      },
      Twitter: {
        id: "community-social-twitter",
        name: "Twitter",
        url: "https://twitter.com/PlayBlankos",
        anchorProps: {
          target: "_blank",
          rel: "noreferrer noopener",
        },
      },
      Facebook: {
        id: "community-social-facebook",
        name: "Facebook",
        url: "https://facebook.com/PlayBlankos",
        anchorProps: {
          target: "_blank",
          rel: "noreferrer noopener",
        },
      },
      Instagram: {
        id: "community-social-instagram",
        name: "Instagram",
        url: "https://www.instagram.com/playblankos",
        anchorProps: {
          target: "_blank",
          rel: "noreferrer noopener",
        },
      },
      YouTube: {
        id: "community-social-youtube",
        name: "YouTube",
        url: "https://www.youtube.com/playblankos",
        anchorProps: {
          target: "_blank",
          rel: "noreferrer noopener",
        },
      },
    },
    childOrder: [
      "Discord",
      "Reddit",
      "Twitter",
      "Facebook",
      "Instagram",
      "YouTube",
    ],
  },
  CreatorCrew: {
    name: "Creator Crew",
    url: globalRoutes.Creators,
  },
  Marketplace: {
    name: "Marketplace",
    url: "https://blankos.mythical.market",
    anchorProps: {
      target: "_blank",
      rel: "noreferrer noopener",
    },
  },
  Shop: {
    name: "The Shop",
    url: globalRoutes.Shop,
  },
  Support: {
    name: "Support",
    url: "https://support.blankos.com",
    anchorProps: {
      target: "_blank",
      rel: "noreferrer noopener",
    },
  },
  FAQ: {
    name: "FAQ",
    url: "/#faqSection",
  },
};

export const mobileRouteOrder: (keyof typeof Routes)[] = [
  "News",
  // "CreatorCrew", // NOTE: this Feature Flag is managed in HeaderNavigation.tsx
  "Shop",
  "Marketplace",
  "Community",
  "FAQ",
  "Support",
];

export const desktopRouteOrder: (keyof typeof Routes)[] = [
  "News",
  // "CreatorCrew", // NOTE: this Feature Flag is managed in HeaderNavigation.tsx
  "Shop",
  "FAQ",
  "Marketplace",
];
