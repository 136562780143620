import React from "react";
import type { Theme } from "@material-ui/core";
import { Container, Grid, Link, Typography } from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/core/styles";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import RedditIcon from "@material-ui/icons/Reddit";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import FooterRoutes from "./routes";

interface Props {
  /** Relative link for pre-fetching via Gatsby */
  rootUrl: URL;
  /** The image that will be used for the Mythical link. */
  mythicalIcon: React.ReactNode;
  /** The image that will be used for the Third Kind link. */
  thirdKindIcon: React.ReactNode;
  /** The image that will be used for the Discord link. */
  discordIcon: React.ReactNode;
  /** The function to send the Download Launcher click event to gtm */
  handleLauncherClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    bottom: 0,
    color: "white",
    fontSize: "16px",
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(9, 0),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  footerContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  socialLinks: {
    display: "flex",
    alignContent: "space-between",
    color: "#fff",
  },
  mythicalLogo: {
    height: 40,
  },
  termsList: {
    listStyleType: "none",
    padding: "0",
    margin: "0",
    "& > li": {
      padding: theme.spacing(1, 0),
      "& > a": {
        fontSize: "16px",
        color: "#fff",
        textDecoration: "underline",
      },
    },
  },
  disclaimer: {
    [theme.breakpoints.down("md")]: {
      paddingTop: "36px",
    },
  },
}));

const SocialIconLink = styled(Link)({
  color: "white",
  padding: "0 5px",
  fontSize: "30px",
});

export const BlankosFooter: React.FC<Props> = ({
  rootUrl,
  mythicalIcon,
  thirdKindIcon,
  discordIcon,
  handleLauncherClick,
}) => {
  const classes = useStyles();

  return (
    <footer className={classes.root}>
      <Container maxWidth="lg" className={classes.footerContainer}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Grid container spacing={5}>
              <Grid item>
                <SocialIconLink
                  id="footer-mythicalWebsite"
                  href="https://mythical.games"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {mythicalIcon}
                </SocialIconLink>
              </Grid>
              <Grid item>
                <SocialIconLink
                  id="footer-thirdKind"
                  href="https://www.thirdkindgames.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {thirdKindIcon}
                </SocialIconLink>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  variant="h6"
                  component="div"
                  style={{ fontSize: "16px" }}
                >
                  Discover
                </Typography>
                <ul className={classes.termsList}>
                  <li>
                    <Link
                      id="footer-downloadLauncher"
                      href={FooterRoutes.DownloadLauncher}
                      onClick={handleLauncherClick}
                    >
                      play now
                    </Link>
                  </li>
                  <li>
                    <Link
                      id="footer-faq"
                      href={FooterRoutes.Faqs}
                      target="_blank"
                    >
                      FAQ
                    </Link>
                  </li>
                  <li>
                    <Link
                      id="footer-support"
                      href="https://support.blankos.com/"
                      rel="noopener noreferrer"
                    >
                      Support
                    </Link>
                  </li>
                  <li>
                    <Link
                      id="footer-privacyNotice"
                      href="https://www.mythical.games/privacy"
                      rel="noopener noreferrer"
                    >
                      Privacy Notice
                    </Link>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="h6"
                  component="div"
                  style={{ fontSize: "16px" }}
                >
                  Terms of Use
                </Typography>
                <ul className={classes.termsList}>
                  <li>
                    <Link
                      id="footer-termsOfUse-mythical"
                      href="https://www.mythical.games/generaltermsofuse"
                      rel="noopener noreferrer"
                    >
                      Mythical Games
                    </Link>
                  </li>
                  <li>
                    <Link
                      id="footer-termsOfUse-blankos"
                      href={FooterRoutes.TermsOfUse}
                      rel="noopener noreferrer"
                    >
                      Blankos Block Party
                    </Link>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container alignItems="flex-end" style={{ paddingTop: "64px" }}>
          <Grid item xs={12} md={6}>
            <Grid
              container
              alignItems="flex-end"
              className={classes.socialLinks}
            >
              <Grid item>
                <SocialIconLink
                  href="https://discord.gg/playblankos"
                  target="_blank"
                  rel="noopener noreferrer"
                  id="footer-discord"
                >
                  {discordIcon}
                </SocialIconLink>
              </Grid>
              <Grid item>
                <SocialIconLink
                  href="https://www.reddit.com/r/PlayBlankos"
                  target="_blank"
                  rel="noopener noreferrer"
                  id="footer-subreddit"
                >
                  <RedditIcon />
                </SocialIconLink>
              </Grid>
              <Grid item>
                <SocialIconLink
                  href="https://twitter.com/PlayBlankos"
                  target="_blank"
                  rel="noopener noreferrer"
                  id="footer-twitter"
                >
                  <TwitterIcon />
                </SocialIconLink>
              </Grid>
              <Grid item>
                <SocialIconLink
                  href="https://facebook.com/PlayBlankos"
                  target="_blank"
                  rel="noopener noreferrer"
                  id="footer-facebook"
                >
                  <FacebookIcon />
                </SocialIconLink>
              </Grid>
              <Grid item>
                <SocialIconLink
                  href="https://www.instagram.com/playblankos"
                  target="_blank"
                  rel="noopener noreferrer"
                  id="footer-instagram"
                >
                  <InstagramIcon />
                </SocialIconLink>
              </Grid>
              <Grid item>
                <SocialIconLink
                  href="https://www.youtube.com/playblankos"
                  target="_blank"
                  rel="noopener noreferrer"
                  id="footer-youtube"
                >
                  <YouTubeIcon />
                </SocialIconLink>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} className={classes.disclaimer}>
            <Typography variant="caption">
              © {new Date().getFullYear()}, Built with Mythical, Inc. Blankos
              Block Party. Mythical and Blankos are trademarks of Mythical, Inc.
              All rights reserved.
              <br />
              This site is protected by reCAPTCHA and the Google{" "}
              <Link
                href="https://policies.google.com/privacy"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </Link>{" "}
              and{" "}
              <Link
                href="https://policies.google.com/terms"
                rel="noopener noreferrer"
              >
                Terms of Service
              </Link>{" "}
              apply.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};
