import { isInGame, windowHelpers } from "../helpers/window-helpers";
import { signOut } from "../helpers/signOut";
import { useSession } from "./useSession";
import { signIn } from "../helpers/signIn";
import { useRouter } from "next/router";
import config from "src/config";
import { useUrlParams } from "@/hooks/useUrlParams";
import { register } from "../helpers/register";

/**
 * Provide authentication information to the client.
 * If we're not in a browser environment, it determines that no authentication information is available.
 * @todo If not in browser, this should probably return that it is initialized so pages render as non-blank.
 */
export function useAuth() {
  const router = useRouter();
  const params = useUrlParams();
  const { data: session, status } = useSession();

  if (!windowHelpers.isInBrowser())
    return {
      initialized: false,
      isLoggedIn: false,
    };

  /*
    GSID is used for tracking, attempt to pull the id from some function in
    the user's cookies.
    Attaches it as a URL parameter for login and adds it to the state parameter for FA
 */
  const gsid = global.window.gsght?.<string>("get", "gsid");
  const gsidParam = params.get("gsid");
  const storedGsidParam = sessionStorage.getItem("gsidParam");

  //Same as GSID but with Singular
  const singular = global.window.singularSdk;
  const sdid = singular?.getSingularDeviceId();
  const sdidParam = params.get("sdid");
  const storedSdidParam = sessionStorage.getItem("sdidParam");

  let currentPage = new URL(config.siteUrl + router.pathname);

  if (router.pathname.includes("[id]")) {
    currentPage = new URL(config.siteUrl + window.location.pathname);
  }

  if (router.pathname.includes("[campaign]")) {
    currentPage = new URL(
      config.siteUrl + window.location.pathname + window.location.search,
    );
  }

  //set gsidValue to param first, then the stored value if no param, then the library if neither of those,
  // and finally an empty string if the library doesn't load and there's no param

  const gsidValue =
    gsidParam != null
      ? gsidParam
      : storedGsidParam != null
      ? storedGsidParam
      : gsid != null
      ? gsid
      : "";

  const sdidValue =
    sdidParam != null
      ? sdidParam
      : storedSdidParam != null
      ? storedSdidParam
      : sdid != null
      ? sdid
      : "";

  if (gsidValue) currentPage.searchParams.append("mmpID", gsidValue);

  if (sdidValue) currentPage.searchParams.append("sdid", sdidValue);

  currentPage.searchParams.append("source", "blankosdotcom");

  const twitchCode = params.get("code");

  if (typeof twitchCode === "string" && router.pathname.includes("/twitch")) {
    currentPage.searchParams.append("code", twitchCode);
  }

  const loginCallbackUrl = currentPage.toString();

  const redirectUri = `${config.siteUrl}/api/auth/callback/fusionauth`;

  //Nextauth doesn't generate a registration URL for us so we have to do it ourselves
  const registerUrl = `${config.fusionauthIssuer}/oauth2/register?client_id=${config.fusionauthId}&tenantId=${config.fusionauthTenantId}&mmpID=${gsidValue}&sdid=${sdidValue}&source=blankosdotcom&scope=openid&response_type=code&redirect_uri=${redirectUri}`;
  const forgotPasswordUrl = `${config.fusionauthIssuer}/password/forgot?tenantId=${config.fusionauthTenantId}&client_id=${config.fusionauthId}&response_type=code&scope=openid%20offline_access&redirect_uri=${redirectUri}`;
  const logoutUrl = `${config.fusionauthIssuer}/oauth2/logout?client_id=${config.fusionauthId}&tenantId=${config.fusionauthTenantId}&post_logout_redirect_uri=${config.siteUrl}`;

  return {
    initialized: status !== "loading",
    mythicalId: session?.user?.id,
    mythicalUsername: session?.user?.email,
    isLoggedIn: status === "authenticated" || isInGame(),
    login: () => signIn("fusionauth", loginCallbackUrl),
    logout: () => signOut(logoutUrl),
    signUp: () => register(registerUrl),
    forgotPassword: () => {
      router.push(forgotPasswordUrl);
    },
  };
}
