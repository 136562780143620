import logger from "@/logger";
import { nanoid } from "nanoid";
import Script from "next/script";
import React, { useMemo } from "react";
import config from "src/config";

const { kountUrl, kountMerchantId } = config;

const onError = (scriptName: string) => (e: unknown) =>
  logger.error(e, `%s failed to load`, scriptName);

const ReCaptchaScript: React.FC = () => (
  <Script
    id="recaptcha"
    src="https://www.google.com/recaptcha/api.js?render=6LeOmKkZAAAAAMc72bzngYXK1zSifLlyb5LIoZNO"
    onError={onError("ReCaptcha")}
  />
);

const KountScript: React.FC = () => {
  const id = useMemo(() => {
    const id = nanoid(32);
    global?.window?.sessionStorage?.setItem?.("kountSessionId", id);
    return id;
  }, []);

  return (
    <Script
      type="text/javascript"
      src={`https://${kountUrl}/collect/sdk?m=${kountMerchantId}&s=${id}`}
      onError={onError("Kount")}
    />
  );
};

const PolystreamScript: React.FC = () => (
  <Script
    id="polystream"
    src="INSERT_POLYSTREAM_SCRIPT_HERE" //TODO: insert Polystream script for streaming Blankos Block Party on PC
    onError={onError("Polystream")}
  />
);

export const ThirdPartyScripts: React.FC = () => {
  return (
    <>
      <KountScript />
      <ReCaptchaScript />
      {/*<PolystreamScript />*/}
      <Script
        src="https://cdn.optimizely.com/js/21097901207.js"
        strategy="beforeInteractive"
      />
    </>
  );
};
