import React, { useState } from "react";
import {
  Badge,
  Box,
  Collapse,
  Container,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  withStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/Menu";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { Link as ClothedLink } from "./Link";
import type { Route } from "./Route";
import { desktopRouteOrder, mobileRouteOrder, Routes } from "./routes";
import useFeatureFlags from "@/hooks/useFeatureFlags";

interface Props {
  /** Relative link for pre-fetching via Gatsby */
  rootUrl: URL;
  /** The image that will be used for the home button. */
  logoImage: React.ReactNode;
  /** The image that will be used in the mobile navigation. */
  logoImageMobileNav: React.ReactNode;
  children: React.ReactNode;
}

interface NavMenu {
  LinkComponent: typeof ClothedLink;
  logoImage: React.ReactNode;
  logoImageMobileNav: React.ReactNode;
  children: React.ReactNode;
}

const useStyles = makeStyles({
  MuiDrawer: {
    backgroundColor: "#2A2C2E",
    justifyContent: "space-between",
    width: "306px",
  },
  MuiTypographyBody1: {
    fontWeight: "bold",
  },
});

const useFeatureFlagHandler = (tabOrderArray: (string | number)[]) => {
  const enableCreatorCrew = useFeatureFlags().enableCreatorCrew;

  // When feature flag is toggled "on" insert the Creator Crew tab in the second position in the overall tab order
  if (enableCreatorCrew && !tabOrderArray.includes("CreatorCrew")) {
    tabOrderArray.splice(1, 0, "CreatorCrew");
  }

  // When feature flag is toggled "off" remove the Creator Crew tab (and 404 will be returned by creators.tsx):
  if (!enableCreatorCrew && tabOrderArray.includes("CreatorCrew")) {
    tabOrderArray.splice(tabOrderArray.indexOf("CreatorCrew"), 1);
  }

  return tabOrderArray;
};

const AttentionBadge: React.FC = ({ children }) => (
  <Badge
    style={{ transform: "translateY(-2px)" }}
    badgeContent="!"
    color="primary"
  >
    {children}
  </Badge>
);

const DropdownLink: React.FC<{
  LinkComponent: typeof ClothedLink;
  route: Route;
  children: React.ReactNode;
}> = ({ LinkComponent, route, children }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <LinkComponent
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={handleClick}
        href={route.url}
      >
        {route.name}
      </LinkComponent>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        style={{ top: "25px" }}
      >
        <Grid container direction="column">
          {children}
        </Grid>
      </Menu>
    </>
  );
};

const RouteHandler: React.FC<{
  LinkComponent: typeof ClothedLink;
  route: Route;
}> = ({ LinkComponent, route }) => {
  const Decoration = route.isNew ? AttentionBadge : React.Fragment;

  const LinkContainer: React.FC = ({ children }) => (
    <Grid item>
      <Decoration>{children}</Decoration>
    </Grid>
  );

  if (!route.children || route.childOrder?.length === 0)
    return (
      <LinkContainer>
        <LinkComponent
          id={route.id}
          href={route.url}
          {...(route.anchorProps || {})}
        >
          {route.name}
          {route.anchorProps?.target?.includes("_blank") ? (
            <OpenInNewIcon
              style={{
                marginLeft: "8px",
                height: "12px",
                width: "12px",
              }}
            />
          ) : (
            <></>
          )}
        </LinkComponent>
      </LinkContainer>
    );

  const SubLink = withStyles({
    root: {
      fontSize: "14px",
      fontWeight: "initial",
    },
  })(LinkComponent);

  return (
    <LinkContainer>
      <DropdownLink LinkComponent={LinkComponent} route={route}>
        {route.childOrder?.map((childKey, i) => {
          const subRoute = route.children?.[childKey];
          if (!subRoute) return <></>;

          return (
            <MenuItem style={{ fontSize: "14px" }} key={i}>
              <RouteHandler LinkComponent={SubLink} route={subRoute} />
            </MenuItem>
          );
        })}
      </DropdownLink>
    </LinkContainer>
  );
};

const RouteHandlerMobile: React.FC<{
  LinkComponent: typeof ClothedLink;
  route: Route;
  closeDrawer: () => void;
}> = ({ LinkComponent, route, closeDrawer }) => {
  const Decoration = route.isNew ? AttentionBadge : React.Fragment;
  const [openSublinks, setOpenSublinks] = useState(false);
  const classes = useStyles();

  if (!route.children || route.childOrder?.length === 0)
    return (
      <LinkComponent
        id={route.id}
        href={route.url}
        onClick={closeDrawer}
        {...(route.anchorProps || {})}
      >
        <ListItem divider style={{ padding: "14px 27px" }}>
          <ListItemText classes={{ primary: classes.MuiTypographyBody1 }}>
            <Decoration>{route.name}</Decoration>
            {route.anchorProps?.target?.includes("_blank") && (
              <OpenInNewIcon
                style={{
                  marginLeft: "14px",
                  height: "16px",
                  width: "16px",
                }}
              />
            )}
          </ListItemText>
        </ListItem>
      </LinkComponent>
    );

  return (
    <div style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.12" }}>
      <ListItem
        button
        onClick={() => setOpenSublinks(!openSublinks)}
        style={{ padding: "14px 27px" }}
      >
        <ListItemText
          classes={{ primary: classes.MuiTypographyBody1 }}
          primary={route.name}
        />
        {openSublinks ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openSublinks} timeout="auto" unmountOnExit>
        <List disablePadding>
          {route.childOrder?.map((childKey, i) => {
            const subRoute = route.children?.[childKey];
            if (!subRoute) return <></>;

            return (
              <LinkComponent
                id={subRoute.id}
                key={i}
                href={subRoute.url}
                {...(subRoute.anchorProps || {})}
              >
                <ListItem style={{ paddingTop: "7px", paddingBottom: "7px" }}>
                  <ListItemText
                    classes={{ primary: classes.MuiTypographyBody1 }}
                    style={{ paddingLeft: "31px" }}
                  >
                    {subRoute.name}
                    {subRoute.anchorProps?.target?.includes("_blank") && (
                      <OpenInNewIcon
                        style={{
                          marginLeft: "14px",
                          height: "16px",
                          width: "16px",
                        }}
                      />
                    )}
                  </ListItemText>
                </ListItem>
              </LinkComponent>
            );
          })}
        </List>
      </Collapse>
    </div>
  );
};

const MobileDrawer: React.FC<NavMenu> = ({
  LinkComponent,
  logoImageMobileNav,
  children,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const classes = useStyles();
  const routeOrder = useFeatureFlagHandler(mobileRouteOrder);

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        style={{ padding: "0 32px" }}
      >
        <Grid item>
          <LinkComponent
            href={Routes.Web.url}
            title={Routes.Web.name}
            {...(Routes.Web.anchorProps || {})}
          >
            {logoImageMobileNav}
          </LinkComponent>
        </Grid>
        <Grid>
          <IconButton onClick={() => setDrawerOpen(true)}>
            <MenuIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Drawer
        anchor="right"
        classes={{ paper: classes.MuiDrawer }}
        keepMounted
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        style={{ zIndex: 10001 }}
      >
        <Box style={{ overflowY: "scroll" }}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{
              backgroundColor: "#2A2C2E",
              borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
              padding: "14px 26px 12px 26px",
              position: "absolute",
              zIndex: 1,
            }}
          >
            <Grid item>
              <LinkComponent
                href={Routes.Web.url}
                title={Routes.Web.name}
                {...(Routes.Web.anchorProps || {})}
              >
                {logoImageMobileNav}
              </LinkComponent>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="close navigation"
                onClick={() => setDrawerOpen(false)}
                style={{ padding: 0 }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <List disablePadding style={{ paddingTop: "64px" }}>
            {routeOrder.map((routeKey, i) => {
              const route = Routes[routeKey];
              if (!route) return <></>;

              return (
                <div key={i}>
                  <RouteHandlerMobile
                    LinkComponent={LinkComponent}
                    route={route}
                    closeDrawer={() => setDrawerOpen(false)}
                  />
                </div>
              );
            })}
          </List>
        </Box>
        <Box style={{ boxShadow: "0px -10px 40px rgba(0, 0, 0, 0.4)" }}>
          {children ? <>{children}</> : <></>}
        </Box>
      </Drawer>
    </>
  );
};

const DesktopNavbar: React.FC<NavMenu> = ({
  LinkComponent,
  logoImage,
  children,
}) => {
  const routeOrder = useFeatureFlagHandler(desktopRouteOrder);

  return (
    <>
      <Container maxWidth="xl">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={children ? 7 : 12}>
            <Grid container spacing={4} alignItems="center">
              <Grid item>
                <LinkComponent
                  href={Routes.Web.url}
                  title={Routes.Web.name}
                  {...(Routes.Web.anchorProps || {})}
                >
                  {logoImage}
                </LinkComponent>
              </Grid>
              <Grid item>
                <Grid container spacing={2} alignItems="center">
                  {routeOrder.map((routeKey, i) => {
                    const route = Routes[routeKey];
                    if (!route) return <></>;

                    return (
                      <RouteHandler
                        LinkComponent={LinkComponent}
                        route={route}
                        key={i}
                      />
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {children ? (
            <Grid item xs={5} style={{ textAlign: "right" }}>
              {children}
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Container>
    </>
  );
};

/**
 * A navigation header with correct linking for local vs remote links. The children will be transcluded into the right section of the top bar on
 *  desktop and the top of the drawer on mobile.
 *
 * @todo Instead of passing around the Link component, this should likely provide the rootUrl via the Context API.
 */
export const HeaderNavigation: React.FC<Props> = ({
  rootUrl,
  children,
  ...props
}) => {
  const Link: React.FC<React.ComponentPropsWithoutRef<typeof ClothedLink>> = ({
    href,
    ...props
  }) => {
    return <ClothedLink href={href} {...props} />;
  };

  return (
    <Box
      component="menu"
      display="flex"
      alignItems="center"
      width="100%"
      height="100px"
      margin={0}
      padding={0}
      zIndex={1}
      style={{
        background:
          "linear-gradient(180deg, rgba(26, 26, 26, 0.5) 0%, rgba(26, 26, 26, 0) 100%)",
      }}
    >
      <Hidden mdDown>
        <DesktopNavbar LinkComponent={Link} {...props}>
          {children}
        </DesktopNavbar>
      </Hidden>
      <Hidden lgUp>
        <MobileDrawer LinkComponent={Link} {...props}>
          {children}
        </MobileDrawer>
      </Hidden>
    </Box>
  );
};
