import React from "react";
import { useAuth } from "@/hooks/useAuth";
import type { Session } from "next-auth";
import { useSession } from "@/hooks/useSession";

const AuthCheck: React.FC = () => {
  const { data } = useSession();
  const auth = useAuth();

  if (data) {
    const { error } = data as Session;
    if (error === "RefreshAccessTokenError") {
      if (auth.logout) auth.logout();
    }
  }

  return <></>;
};

export default AuthCheck;
