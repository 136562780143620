import React, { createContext, useReducer } from "react";
import type { CookiesPreferences } from "src/components/cookies/useCookiePreferences";

interface CookiePreferencesProviderType {
  loaded: boolean;
  cookiePreferences: CookiesPreferences;
  setLoaded: (loaded: boolean) => void;
  setCookiePreferences: (cookiePreferences: CookiesPreferences) => void;
}

type CookiePreferencesReducerAction =
  | { type: "UPDATE_COOKIE_PREFERENCES"; payload: CookiesPreferences }
  | { type: "UPDATE_LOADED"; payload: boolean };

interface CookiePreferencesProviderProps {
  children?: React.ReactNode;
}

const initialState: CookiePreferencesProviderType = {
  loaded: false,
  cookiePreferences: {
    acknowledged: false,
    acknowledgedTimestamp: undefined,
    accepted: false,
    acceptedTimestamp: undefined,
    advanced: {
      saleOfPersonalData: false,
      socialMedia: false,
      targeting: false,
      performance: false,
    },
  },
  setLoaded(loaded) {
    return loaded;
  },
  setCookiePreferences(preferences) {
    return preferences;
  },
};

export const CookiePreferencesContext = createContext(initialState);

const CookiePreferencesReducer = (
  state: CookiePreferencesProviderType,
  action: CookiePreferencesReducerAction,
) => {
  switch (action.type) {
    case "UPDATE_COOKIE_PREFERENCES":
      return { ...state, cookiePreferences: { ...action.payload } };

    case "UPDATE_LOADED":
      return { ...state, loaded: action.payload };

    default:
      return state;
  }
};

export const CookiePreferencesProvider: React.FC<
  CookiePreferencesProviderProps
> = ({ children }) => {
  const [state, dispatch] = useReducer(CookiePreferencesReducer, initialState);

  const setLoaded = (loaded: boolean) => {
    dispatch({
      type: "UPDATE_LOADED",
      payload: loaded,
    });
  };

  const setCookiePreferences = (preferences: CookiesPreferences) => {
    dispatch({
      type: "UPDATE_COOKIE_PREFERENCES",
      payload: preferences,
    });
  };

  const value = {
    loaded: state.loaded,
    cookiePreferences: state.cookiePreferences,
    setLoaded,
    setCookiePreferences,
  };

  return (
    <CookiePreferencesContext.Provider value={value}>
      {children}
    </CookiePreferencesContext.Provider>
  );
};
