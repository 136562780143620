import { default as RouterLink } from "next/link";
import React from "react";
import { Link as MuiLink, withStyles } from "@material-ui/core";

/** Wraps an MuiLink to use Gatsby's links for internal destinations, otherwise use an anchor. */
const UnstyledLink: React.FC<React.ComponentProps<typeof MuiLink>> = ({
  href,
  ref,
  ...props
}) => {
  // need to account for undefined href per TS definitions
  return !href ? (
    <MuiLink href={href} {...props} />
  ) : (
    <RouterLink href={href} passHref>
      <MuiLink {...props} />
    </RouterLink>
  );
};

export const Link = withStyles({
  root: {
    color: "inherit",
    fontSize: "16px",
    fontWeight: "bold",
    textDecoration: "none",
  },
})(UnstyledLink);
